<template>
  <c-flex
    position="relative"
    width="100%"
    height="100%"
    flex-direction="column"
    justify-content="center"
    margin-inline="auto"
    :background-color="['#F2F2F2', '#FFF']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['1rem 1rem 80px 1rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', 'auto']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumbLabel"
      />
    </Portal>

    <c-box v-if="isLoadingData">
      <c-flex
        width="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
        padding="128px 24px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
          margin-bottom="16px"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Profil Gizi...
        </c-text>
      </c-flex>
    </c-box>
    <EmptyState 
      v-else-if="!isHaveMealPlanMonths"
      title="Kamu belum memiliki program aktif"
      description="Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu."
      button-text="Beli Program"
      button-route="client.select-program"
    />
    <EmptyState
      v-else-if="!dataKondisiGiziku"
      title="Profil gizimu masih kosong"
      description="Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!"
      button-text="Beranda"
      button-route="client.index"
    />
    
    <c-flex
      v-else
      background-color="white"
      :padding="['16px', '0']"
      width="100%"
      flex-direction="column"
      gap="16px"
    >
      <c-flex
        gap="2rem"
        :margin-bottom="['0.5rem','1rem']"
      >
        <c-button
          variant="ghost"
          padding="0px"
          width="30px"
          height="30px"
          min-width="30x"
          :display="['none', 'flex']"
          align-items="center"
          @click="$router.back()"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-chevron-left.svg')"
            height="20px"
            width="20px"
          />
        </c-button>
        <c-box
          width="100%"
        >
          <DownloadMealPlan
            v-if="!dataKondisiGiziku"
            :program-id-local="activeProgram?.id"
            :month="$route.params.month"
          />

          <c-text
            :display="['none', 'block']"
            color="neutral.black"
            font-size="20px"
            font-weight="700"
            text-align="center"
          >
            Profil Gizi
          </c-text>
        </c-box>
      </c-flex>

      <c-box>
        <c-flex
          :margin-top="['12px', '16px']"
          w="100%"
          :gap="['10px', '20px']"
          align-items="center"
        >
          <c-image
            :w="['60px', '80px']"
            :h="['60px', '80px']"
            object-fit="cover"
            :border-radius="['60px', '80px']"
            :src="getPhotoUser(profile?.photoUrl)"
          />
          <c-box w="100%">
            <c-text
              :font-size="['16px', '18px']"
              font-weight="500"
              color="neutral.black"
              margin-bottom="8px"
            >
              {{ profile?.firstName }} {{ profile?.lastName }}
            </c-text>
            <c-text
              :font-size="['12px', '14px']"
              font-weight="400"
              color="primary.400"
            >
              {{ profile?.phone }}
            </c-text>
          </c-box>
        </c-flex>

        <c-flex
          margin-top="16px"
          gap="8px"
          align-items="center"
        >
          <c-box
            :width="['12px', '20px']"
            :height="['12px', '20px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-account-circle.svg')"
              fill="#555555"
              width="100%"
              height="100%"
            />
          </c-box>

          <c-text
            color="neutral.darkGray"
            :font-size="['10px', '14px']"
            line-height="normal"
          >
            Dibuat oleh {{ getFullnameAndTitle(`${(dataKondisiGiziku?.nutritionist?.firstName || '')} ${(dataKondisiGiziku?.nutritionist?.lastName || '')}`.trim(), dataKondisiGiziku?.nutritionist?.
              education || []) }} - Ahli gizi Dietela
          </c-text>
        </c-flex>

        <c-flex
          :margin-top="['24px', '32px']"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            :font-size="['16px', '20px']"
            :font-weight="['600', '700']"
          >
            Kondisi Giziku
          </c-text>
        </c-flex>
        <BaseDivider />

        <c-box v-if="dataKondisiGiziku?.nutritionalConditions">
          <CardInfoKondisiGiziku
            :type="dataKondisiGiziku?.nutritionalConditions?.calculationType || ''"
            :imt-status="parseInfo.status"
            :imt-value="parseInfo.value"
            :value-indicator="parseIndicatorValue"
            popover-text="Indeks Massa Tubuh (IMT) adalah pengukuran untuk menentukan apakah berat badan Anda sehat berdasarkan tinggi dan berat badan."
          />

          <c-grid
            :display="['grid', 'flex']"
            :template-columns="['repeat(2, 1fr)', 'repeat(auto-fit, minmax(200px, 1fr))']"
            margin-top="16px"
            gap="16px"
            flex-wrap="wrap"
          >
            <CardInfoOutline
              title="Umur"
              :value="`${dataKondisiGiziku?.nutritionalConditions?.age ?? '-'} tahun`"
            />
            <CardInfoOutline
              title="Jenis kelamin"
              :value="dataKondisiGiziku?.nutritionalConditions?.gender && dataKondisiGiziku?.nutritionalConditions?.gender === 'female'
                ? 'Perempuan'
                : dataKondisiGiziku?.nutritionalConditions?.gender && dataKondisiGiziku?.nutritionalConditions?.gender === 'male'
                  ? 'Laki-laki'
                  : '-'"
            />
            <CardInfoOutline
              :title="parseInfo.cardInfo3.label"
              :value="parseInfo.cardInfo3.value"
            />
            <CardInfoOutline
              title="BB ideal"
              :value="parseInfo.cardInfo4.value"
            />

            <CardInfoOutline
              v-if="parseInfo.cardInfo5.value"
              title="TB/PB ideal"
              :value="parseInfo.cardInfo5.value"
            />
          </c-grid>

          <c-flex
            :width="['100%', 'fit-content']"
            :max-width="['100%', 'fit-content']"
            margin-top="16px"
          >
            <CardInfoOutlineHighlight
              title="Total Kebutuhan Energi (kkal)"
              :value="`${dataKondisiGiziku?.nutritionalConditions?.totalEnergyNeeded || '-'} kkal`"
            />
          </c-flex>
        </c-box>
        <c-box
          v-else
          margin-top="16px"
        >
          <NoData
            text="Kondisi Giziku belum terisi"
          />
        </c-box>

        <!-- Asupan Gizi -->
        <c-flex
          :margin-top="['24px', '32px']"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            :font-size="['16px', '20px']"
            :font-weight="['600', '700']"
          >
            Asupan Gizi
          </c-text>
        </c-flex>
        <BaseDivider />
        <c-box v-if="nutritionalIntake">
          <c-grid
            margin-top="16px"
            width="100%"
            :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
            gap="16px"
          >
            <CardInfoAsupanGizi
              key="energy"
              title="Energi"
              :current="Number(nutritionalIntake?.energyIntakeCurrent) ?? 0"
              :requirement="Number(dataKondisiGiziku?.nutritionalConditions?.totalEnergyNeeded) ?? 0"
              :status="nutritionalIntake?.energyStatus"
              :note="nutritionalIntake?.energyIntakeNote"
              unit="kkal"
            />

            <CardInfoAsupanGizi
              key="protein"
              title="Protein"
              :current="Number(nutritionalIntake?.proteinIntakeCurrent) ?? 0"
              :requirement="Number(nutritionalIntake?.proteinRequirement) ?? 0"
              :status="nutritionalIntake?.proteinStatus"
              :note="nutritionalIntake?.proteinIntakeNote"
              unit="gram"
            />

            <CardInfoAsupanGizi
              key="fat"
              title="Lemak"
              :current="Number(nutritionalIntake?.fatIntakeCurrent) ?? 0"
              :requirement="Number(nutritionalIntake?.fatRequirement) ?? 0"
              :status="nutritionalIntake?.fatStatus"
              :note="nutritionalIntake?.fatIntakenote"
              unit="gram"
            />

            <CardInfoAsupanGizi
              key="carbohydrate"
              title="Karbohidrat"
              :current="Number(nutritionalIntake?.carboIntakeCurrent) ?? 0"
              :requirement="Number(nutritionalIntake?.carbohydrateRequirement) ?? 0"
              :status="nutritionalIntake?.carbohydrateStatus"
              :note="nutritionalIntake?.carboIntakeNote"
              unit="gram"
            />

            <CardInfoAsupanGizi
              key="fiber"
              title="Serat"
              :current="Number(nutritionalIntake?.fiberIntakeCurrent) ?? 0"
              :requirement="Number(nutritionalIntake?.fiberNeedsForAgeCategory) ?? 0"
              :status="nutritionalIntake?.fiberStatus"
              :note="nutritionalIntake?.fiberIntakeNote"
              unit="gram"
            />
          </c-grid>
        </c-box>
        <c-box
          v-else
          margin-top="16px"
        >
          <NoData
            text="Asupan Gizi belum terisi"
          />
        </c-box>

        <!-- Kondisi Tubuh -->
        <c-flex
          :margin-top="['24px', '32px']"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            :font-size="['16px', '20px']"
            :font-weight="['600', '700']"
          >
            Kondisi Tubuh
          </c-text>
        </c-flex>
        <BaseDivider />
        <c-box
          v-if="bodyConditions.length > 0"
          :font-size="['14px', '16px']"
        >
          <c-grid
            width="100%"
            :template-columns="['repeat(3, 1fr)', 'repeat(4, 1fr)']"
            :background-color="['primary.400', 'primary.50']"
            :color="['neutral.white', 'neutral.black']"
            :font-size="['14px', '16px']"
            font-weight="500"
            text-align="center"
            border-top-left-radius="12px"
            border-top-right-radius="12px"
            margin-top="16px"
            margin-bottom="8px"
          >
            <c-box
              :display="['none', 'block']"
              text-align="center"
              padding-block="8px"
            >
              Indikator
            </c-box>
            <c-box
              text-align="center"
              padding-block="8px"
            >
              <c-box
                :display="['none', 'inline']"
                as="span"
              >
                Nilai
              </c-box> Sekarang
            </c-box>
            <c-box
              text-align="center"
              padding-block="8px"
            >
              <c-box
                :display="['none', 'inline']"
                as="span"
              >
                Nilai
              </c-box> Standard
            </c-box>
            <c-box
              text-align="center"
              padding-block="8px"
            >
              Status
            </c-box>
          </c-grid>
          <c-grid
            v-for="(item, index) in bodyConditions"
            :key="index"
            w="100%"
            :template-columns="['repeat(3, 1fr)', 'repeat(4, 1fr)']"
            :gap="['6px', '16px']"
            border-bottom-width="1px"
            border-bottom-color="neutral.superLightGray"
            padding-block="4px"
            margin-bottom="8px"
            text-align="center"
          >
            <c-box
              :grid-column="['span 3 / span 3', 'span 1 / span 1']"
              padding-block="8px"
              font-weight="500"
              :background-color="['primary.50', 'white']"
            >
              {{ item?.indicator ?? '-' }}
            </c-box>
            <c-box
              padding-block="8px"
              text-transform="lowercase"
              align-self="center"
            >
              {{ item?.currentCondition ?? '-' }} {{ item?.currentConditionUnit }}
            </c-box>
            <c-box
              padding-block="8px"
              text-transform="lowercase"
              align-self="center"
            >
              {{ item?.standardValue ?? '-' }} {{ item?.standardValueUnit }}
            </c-box>
            <c-box
              :padding-block="['4px', '8px']"
              font-weight="500"
              :background-color="getBobyConditionStatusColor(item?.conclusion).background"
              :color="getBobyConditionStatusColor(item?.conclusion).color"
              :border-radius="['8px', '0px']"
              height="fit-content"
              align-self="center"
            >
              {{ item?.conclusion ?? '-' }}
            </c-box>
          </c-grid>
        </c-box>
        <c-box
          v-else
          margin-top="16px"
        >
          <NoData
            text="Kondisi Tubuh belum terisi"
          />
        </c-box>

        <!-- Tujuan Mealplan -->
        <c-box v-if="!targetAndSuggestions">
          <c-flex
            :margin-top="['24px', '32px']"
            align-items="center"
            justify-content="space-between"
          >
            <c-text
              :font-size="['16px', '20px']"
              :font-weight="['600', '700']"
            >
              Target dan Saran
            </c-text>
          </c-flex>
          <BaseDivider />

          <c-box
            margin-top="16px"
          >
            <NoData
              text="Target dan Saran belum terisi"
            />
          </c-box>
        </c-box>

        <c-box v-else>
          <!-- Tujuan Meal Plan -->
          <c-text
            :margin-top="['24px', '32px']"
            :font-size="['16px', '20px']"
            :font-weight="['600', '700']"
          >
            Tujuan Mealplan
          </c-text>
          <BaseDivider />

          <c-text
            margin-top="10px"
            color="neutral.superDarkGray"
            :font-size="['14px', '16px']"
          >
            {{ targetAndSuggestions.mealPlanGoals }}
          </c-text>

          <c-grid
            gap="16px"
            :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
            margin-top="16px"
          >
            <CardInfoThingsThatNeed
              label="Yang perlu dipertahankan"
              :items="targetAndSuggestions?.thingsThatNeedToBeMaintained || ''"
            />
            <CardInfoThingsThatNeed
              color="warning"
              label="Yang harus diperbaiki"
              :items="targetAndSuggestions?.thingsThatNeedToBeImproved || ''"
            />
          </c-grid>

          <!-- Target -->
          <c-flex
            :margin-top="['48px', '72px']"
            align-items="center"
            justify-content="flex-start"
            position="relative"
          >
            <c-image
              position="absolute"
              :src="require('@/assets/images/undraw-target.svg')"
              :height="['80px', '120px']"
              :left="['20px']"
            />
            <c-text
              :padding-left="['120px', '170px']"
              :font-size="['16px', '20px']"
              :font-weight="['600', '700']"
            >
              Target
            </c-text>
          </c-flex>
          <BaseDivider />

          <c-box
            as="ol"
            :margin-top="['24px', '42px']"
            padding-left="24px"
          >
            <div
              v-if="typeof targetAndSuggestions?.targetForThisPeriod === 'string'"
              v-html="targetAndSuggestions?.targetForThisPeriod"
            />
            <c-box
              v-else
              v-for="(item, index) in targetAndSuggestions?.targetForThisPeriod || []"
              :key="index"
              as="li"
              :font-size="['14px', '16px']"
            >
              <c-text
                color="neutral.superDarkGray"
              >
                {{ item }}
              </c-text>
            </c-box>
          </c-box>

          <!-- Saran -->
          <c-flex
            :margin-top="['36px', '66px']"
            align-items="center"
            justify-content="flex-start"
            position="relative"
          >
            <c-image
              position="absolute"
              :src="require('@/assets/images/undraw-to-do-list.svg')"
              :height="['65px', '90px']"
              :left="['10px']"
            />
            <c-text
              :padding-left="['120px', '170px']"
              :font-size="['16px', '20px']"
              :font-weight="['600', '700']"
            >
              Saran - saran
            </c-text>
          </c-flex>
          <BaseDivider />

          <div
            v-if="typeof targetAndSuggestions?.nutritionAndLifestyleAdvice === 'string'"
            v-html="targetAndSuggestions?.nutritionAndLifestyleAdvice"
          />
          <c-box
            v-else
            as="ol"
            :margin-top="['24px', '42px']"
            padding-left="24px"
          >
            <c-box
              v-for="(item, index) in targetAndSuggestions?.nutritionAndLifestyleAdvice || []"
              :key="index"
              as="li"
              :font-size="['14px', '16px']"
            >
              <c-text
                color="neutral.superDarkGray"
              >
                {{ item }}
              </c-text>
            </c-box>
          </c-box>
        </c-box>

        <!--  xxx -->
        <c-flex
          width="100%"
          margin-top="16px"
          background-color="secondary.50"
          padding-block="20px"
          padding-inline="20px"
          align-items="center"
          justify-content="center"
        >
          <c-flex
            max-width="330px"
            flex-direction="column"
            align-items="center"
            gap="16px"
          >
            <c-flex
              align-items="center"
              gap="8px"
            >
              <c-text
                :font-size="['14px', '16px']"
                color="#111"
                font-weight="500"
              >
                Temukan berbagai variasi golongan makan beserta takaran porsinya
              </c-text>
              <c-image
                :src="require('@/assets/images/image-lightbulb-moment.png')"
                :width="['80px', '120px']"
              />
            </c-flex>
            <c-button
              variant="solid"
              variant-color="primary"
              border-radius="30px"
              :height="['40px', '47px']"
              font-weight="500"
              :font-size="['14px', '16px']"
              @click="$router.push({
                name: 'client.glossaries',
              })"
            >
              Lihat Glossary Foto Makanan
            </c-button>
          </c-flex>
        </c-flex>
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import EmptyState from '@/views/client/meal-plan/widgets/empty-state.vue'
import CardInfoKondisiGiziku from '@/components/meal-plan/_widgets/card-info-kondisi-giziku.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
// import BaseModal from '@/components/elements/base-modal.vue'
// import BaseText from '@/components/elements/base-text.vue'
// import BaseButton from '@/components/elements/base-button.vue'
import CardInfoOutline
  from '@/components/meal-plan/_widgets/card-info-outline.vue'
import CardInfoOutlineHighlight
  from '@/components/meal-plan/_widgets/card-info-outline-highlight.vue'
import CardInfoThingsThatNeed
  from '@/components/meal-plan/_widgets/card-info-things-that-need.vue'
import CardInfoAsupanGizi
  from '@/components/meal-plan/_widgets/card-info-asupan-gizi.vue'
import NoData from '@/components/no-data.vue'  
import { getFullnameAndTitle } from '@/utils/specific-case-utils'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import DownloadMealPlan from '@/views/client/meal-plan/widgets/download-meal-plan.vue'
import { CBox, CFlex } from '@chakra-ui/vue'

export default {
  components: {
    CFlex, CBox,
    DownloadMealPlan,
    BreadcrumbPath,
    EmptyState,
    CardInfoKondisiGiziku,
    BaseDivider,
    // BaseModal,
    // BaseText,
    // BaseButton,
    CardInfoOutline,
    CardInfoOutlineHighlight,
    CardInfoThingsThatNeed,
    CardInfoAsupanGizi,
    NoData,
  },
  mixins: [generalMixin],
  beforeRouteLeave(to, from, next) {
    if (!this.isTourActive) {
      next()
    }
  },
  props: {
    isHaveMealPlanMonths: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    activeProgram: {
      type: [Object],
      default: () => {},
    },
    isTourActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      profile: (s) => s.profile.dataProfile,
    }),
    breadcrumbLabel() {
      return [
        {
          label: 'Dashboard',
          href: '/',
        },
        {
          label: 'Panduan Makan',
          href: '/meal-plan',
        },
        {
          label: 'Profil Gizi',
          isCurrent: true,
        },
      ]
    },
    dataKondisiGiziku() {
      return this.data && Object.keys(this.data).length > 0 ? this.data : null
    },
    nutritionalIntake() {
      return this.data?.nutritionalIntake || null
    },
    bodyConditions() {
      return this.data?.bodyConditions?.data || []
    },
    targetAndSuggestions() {
      return this.data?.targetAndSuggestions || null
    },
    parseInfo() {
      switch (this.dataKondisiGiziku?.nutritionalConditions?.calculationType) {
        case 'adult':
          return {
            status: this.dataKondisiGiziku?.nutritionalConditions?.imt?.status || '-',
            value: this.dataKondisiGiziku?.nutritionalConditions?.imt?.value || 0,
            cardInfo3: {
              label: 'Berat badan/Tinggi badan',
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.weight || '-'}kg / ${this.dataKondisiGiziku?.nutritionalConditions?.height || '-'}cm`,
            },
            cardInfo4: {
              value: this.dataKondisiGiziku?.nutritionalConditions?.idealWeightRange || '-',
            },
            cardInfo5: {
              value: null,
            },
          }
        default:
          return {
            status: this.dataKondisiGiziku?.nutritionalConditions?.nutritionalStatusConclusion || '-',
            value: this.dataKondisiGiziku?.nutritionalConditions?.zScoreConclusion || 0,
            cardInfo3: {
              label: 'Berat badan/Tinggi atau Panjang badan',
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.weight || '-'}kg / ${this.dataKondisiGiziku?.nutritionalConditions?.height || '-'}cm`,
            },
            cardInfo4: {
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.childIdealWeight || '-'} kg`,
            },
            cardInfo5: {
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.childIdealHeight || '-'} cm`,
            },
          }
      }
    },
    parseIndicatorValue() {
      switch (this.dataKondisiGiziku?.nutritionalConditions?.calculationType) {
        case 'adult':
          switch (this.dataKondisiGiziku?.nutritionalConditions?.imt?.status) {
            case 'Obesitas 2':
              return 100
            case 'Obesitas 1':
              return 90
            case 'Preobesitas':
              return 75
            case 'Normal':
              return 50
            case 'Berat Badan Kurang':
            default:
              return 0
          }
        default:
          switch (this.dataKondisiGiziku?.nutritionalConditions?.nutritionalStatusConclusion) {
            case 'Obesitas (obese)':
              return 100
            case 'Gizi lebih (overweight)':
              return 90
            case 'Berisiko Gizi lebih (possible risk of overweight)':
              return 75
            case 'Gizi baik (normal)':
              return 50
            case 'Gizi kurang (wasted)':
              return 25
            case 'Gizi buruk (severely wasted)':
            default:
              return 0
          }
      }
    },
  },
  mounted() {
    if (!this.profile || this.profile?.id !== this.user?.id) {
      this.getProfileClient()
    }
  },
  methods: {
    ...mapActions({
      getProfileClient: 'profile/getProfileClient',
    }),
    getFullnameAndTitle,
    getBobyConditionStatusColor(status) {
      switch(status) {
        case 'Berlebih':
          return {
            background: 'danger.50',
            color: 'danger.400',
          }
        case 'Kurang':
          return {
            background: 'warning.50',
            color: 'warning.400',
          }
        case 'Cukup':
          return {
            background: 'success.50',
            color: 'success.400',
          }
        case 'Normal':
          return {
            background: 'primary.50',
            color: 'primary.400',
          }
        case 'Tidak ada data':
          return {
            background: 'neutral.lightGray',
            color: 'neutral.black',
          }
        default:
          return {
            background: 'neutral.superLightGray',
            color: 'neutral.black',
          }
      }
    },
  },
}
</script>

<style>

</style>